<template>
  <div>
    <v-card>
      <v-card-text>
        <div>
          <v-data-table
            :caption="$t('general.documents')"
            :headers="headers"
            :items="listeDocuments"
            hide-default-footer
            hide-default-header
            disable-pagination
            no-data-text="Aucun document"
            dense
          >
            <template v-slot:[`item.actions`]="{item}">
              <v-btn
                class="px-2 ml-1 success"
                min-width="0"
                small
                @click="onTéléchargerDocument(item)"
              >
                <v-icon small>
                  mdi-download
                </v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1 warning"
                min-width="0"
                small
                @click="onEffacerDocument(item)"
              >
                <v-icon small>
                  mdi-minus
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
        <div
          class="drop text-center justify-center mt-3 pt-4"
          @drop.prevent="onAjouterFichier"
          @dragover.prevent=""
          @dragenter="dragging=true"
          @dragleave="dragging=false"
        >
          <div center>
            {{ $t('kiosque.glissez_des_fichiers_ici_pour_ajouter_des_documents') }}
          </div>
          <v-icon
            large
            class="pt-3"
          >
            mdi-cloud-upload-outline
          </v-icon>
        </div>
        <v-file-input
          v-model="fichier"
          :label="$t('general.televerser_un_fichier')"
          @change="onAjouterFicher()"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="onTéléchargerRapport()"
        >
          <v-icon left>
            mdi-download
          </v-icon>
          {{ $t('kiosque.telecharger_le_rapport_de_consultation_des_documents') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped>
div.drop{
  border : 2px dashed #c0c0c0;
  height: 100px;
  color: #505050;
  background-color: #f0f0f0;
}
</style>
<script>
  import restApiService from '@/services/restApiService.js'

  export default {
    props: {
      kiosque: Object,
    },
    data: () => (
      {
        docs: [],
        files: [],
        headers: [
          { text: 'Nom', value: 'nom' },
          { text: 'Actions', value: 'actions' },
        ],
        fichier: undefined,

      }
    ),
    computed: {
      listeDocuments () {
        if (this.kiosque) { return this.kiosque.listeDocuments }
        return {}
      },
    },
    methods: {
      onAjouterFichier (e) {
        const droppedFiles = e.dataTransfer.files
        if (!droppedFiles) return;
        ([...droppedFiles]).forEach(f => {
          this.files.push(f)
          const reader = new FileReader()
          reader.onloadend = (e) => {
            const data = window.btoa(reader.result)
            const document = {
              nom: f.name,
              contenuContentType: f.type,
              contenu: data,
            }
            // f.type -> exploiter ?
            this.sauvegarderDocuments(document)
            this.docs.push(document)
          }
          reader.readAsBinaryString(f)
        })
      },
      sauvegarderDocuments (d) {
        d.kiosque = { id: this.kiosque.id }
        const url = `/api/documents/kiosque/${this.kiosque.id}/televerser`
        const info = {
          nom: d.nom,
          contentType: d.contenuContentType,
          contenu: d.contenu,
        }
        restApiService.post(url, info).then((res) => {
          this.$emit('maj')
        }).catch((erreur) => alert(erreur))
      },
      onAjouterFicher () {
        const reader = new FileReader()
        reader.onloadend = (e) => {
          const data = window.btoa(reader.result)
          const document = {
            nom: this.fichier.name,
            contentType: this.fichier.type,
            contenu: data,
          }
          const url = `/api/documents/kiosque/${this.kiosque.id}/televerser`
          restApiService.post(url, document).then((res) => {
            this.$emit('maj')
            this.fichier = null
          }).catch((erreur) => alert(erreur))
        }
        reader.readAsBinaryString(this.fichier)
      },
      onEffacerDocument (d) {
        restApiService
          .delete('/api/documents/' + d.id)
          .then(() => {
            this.$emit('maj')
          })
          .catch((erreur) => alert(erreur))
      },
      onTéléchargerDocument (doc) {
        restApiService.post(`/api/webUser/visite/document/${doc.id}`)
        restApiService.getblob(`/api/documents/${doc.id}/telecharger/`).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', doc.nom)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
          .catch(error => alert(error))
      },
      onTéléchargerRapport () {
        restApiService.getblob(`/api/document-telechargements/kiosque/${this.kiosque.id}/rapport.xlsx`).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'rapport.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
          .catch(error => alert(error))
      },
    },
  }
</script>
