<template>
  <div>
    <div
      class="['dropzone-area', dragging ? 'dragenterClass' : ''] elevation-4"
      @drop.prevent="addFile"
      @dragover.prevent=""
      @dragenter="dragging=true"
      @dragleave="dragging=false"
    >
      <v-img
        :src="imgsrc"
        contain
      />
    </div>
    <v-file-input
      v-model="fichier"
      :label="$t('general.televerser_un_fichier')"
      @change="majImage()"
    />
  </div>
</template>
<style scoped>
div.dragenterClass{
    border:1px solid green;
}
</style>
<script>
  export default {
    props: {
      media: Object,
    },
    data: () => ({
      fichier: undefined,
      imgsrc: undefined,
      dragging: false,
    }),
    watch: {
      media: {
        handler (newValue) {
          if (newValue !== undefined) {
            this.imgsrc = `data:${this.media.dataContentType};base64, ${this.media.data}`
          }
        },
      },
    },
    methods: {
      majImage () {
        const reader = new FileReader()
        reader.onloadend = (e) => {
          const data = window.btoa(reader.result)
          this.imgsrc = `data:${this.fichier.type};base64, ${data}`
          const m = {
            id: this.media.id,
            data: data,
            dataContentType: this.fichier.type,
            nom: this.fichier.name,
          }
          this.$emit('update:media', m)
        }
        reader.readAsBinaryString(this.fichier)
      },
      addFile (e) {
        const droppedFiles = e.dataTransfer.files
        if (!droppedFiles) return
        this.fichier = droppedFiles[0]
        this.majImage()
      },

    },
  }
</script>
