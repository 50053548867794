<template>
  <v-container
    fluid
    tag="section"
    data-cy="pageEditionKiosque"
  >
    <v-row>
      <v-col md="8">
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ kiosque.nom }}
              <span class="body-1">— {{ $t('edition.atelier') }}</span>
            </div>
          </template>
          <v-form>
            <v-text-field
              v-model="kiosque.nom"
              :label="$t('general.nom')"
              data-cy="tfNom"
            />
          </v-form>
          <v-row>
            <v-col>
              <label>{{ $t('general.description') }}</label>
              <cnx-editeur
                :texte.sync="kiosque.description"
                data-cy="ckeDescription"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <h6 class="display-1 mb-1 grey--text">
                {{ $t('general.logo') }}
              </h6>
              <div>
                <boite-media :media.sync="mediaLogo" />
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <h6 class="display-1 mb-1 grey--text">
                {{ $t('general.image') }}
              </h6>
              <boite-media :media.sync="mediaImage" />
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="mr-0"
              data-cy="btnSauvegarder"
              @click="onSauvegarder()"
            >
              {{ $t('general.sauvegarder') }}
            </v-btn>
          </template>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-title>
            <v-icon
              left
            >
              mdi-shield-star-outline
            </v-icon>
            {{ $t('gestion_des_kiosques.edition_kiosque.plan_commanditaire') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="kiosque.planCommanditeNom"
                  :label="$t('general.nom')"
                  data-cy="tfCommanditeNom"
                />

                <v-text-field
                  v-model="kiosque.planCommanditePoids"
                  type="number"
                  data-cy="tfCommanditePoids"
                  :label="$t('gestion_des_kiosques.edition_kiosque.poids')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-color-picker
                  v-model="couleurCommandite"
                  dot-size="1"
                  hide-canvas
                  hide-sliders
                  mode="hexa"
                  show-swatches
                  swatches-max-height="100"
                  elevation="12"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <base-material-card
      icon="mdi-account-tie"
      data-cy="cardResponsable"
    >
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t('gestion_des_kiosques.edition_kiosque.responsables') }}
        </div>
      </template>
      <v-data-table
        :headers="headers"
        :items="kiosque.listeResponsables"
        no-data-text="Aucun responsable"
        :footer-props="{
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                   pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="px-2 ml-1 warning"
            min-width="0"
            small
            @click="onEnleverResponsable(item.id)"
          >
            <v-icon small>
              mdi-minus
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <selection-usager
      :usagers-inscrits="kiosque.listeResponsables.map(i => i.usager.id)"
      @usager-selection="onResponsableIdentifié($event)"
       />
    </base-material-card>
    <boite-documents
      :kiosque="kiosque"
      @maj="chargerDonnées()"
    />
  </v-container>
</template>

<script>
  import BoiteMedia from './../../composantes/BoiteMedia'

  import restApiService from '@/services/restApiService.js'
  import SelectionUsager from './../../composantes/SelectionUsager'
  import BoiteDocuments from './../../composantes/BoiteDocuments'
  import CnxEditeur from './../../composantes/CnxEditeur'
  import i18n from '@/i18n.js'

  export default {
    components: {
      SelectionUsager,
      BoiteMedia,
      BoiteDocuments,
      CnxEditeur,
    },
    data: () => ({
      kiosque: {},
      nouveau: false,
      fichiers: null,
      imgsrc: null,
      options: {},
      headers: [
        { text: i18n.t('gestion_des_kiosques.edition_kiosque.prenom'), value: 'usager.prenom' },
        { text: i18n.t('gestion_des_kiosques.edition_kiosque.nom'), value: 'usager.nom' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
      mediaImage: {},
      mediaLogo: {},
    }),
    computed: {
      couleurCommandite: {
        get () {
          var hexa = this.kiosque.planCommanditeCouleur || '#E91E63'
          return hexa
        },
        set (v) {
          this.kiosque.planCommanditeCouleur = v
        },
      },
    },
    mounted () {
      window.testvmek = this
      this.nouveau = this.$route.params.idkiosque === 'nouveau'
      if (!this.nouveau) {
        this.chargerDonnées()
        console.log(this.$route)
      }
    },
    methods: {
      chargerDonnées () {
        restApiService
          .get(`/api/kiosques/${this.$route.params.idKiosque}`)
          .then(result => {
            this.kiosque = result.data
            if (this.kiosque.mediaImage) {
              restApiService.getMedia(this.kiosque.mediaImage).then(m => {
                this.mediaImage = m.data
              })
            }
            if (this.kiosque.mediaLogo) {
              restApiService.getMedia(this.kiosque.mediaLogo).then(m => {
                this.mediaLogo = m.data
              })
            }
          })
          .catch(erreur => alert(erreur))
      },
      chargerResponsables () {
        restApiService
          .get(`/api/kiosques/${this.$route.params.idKiosque}`)
          .then(result => {
            this.kiosque.listeResponsables = result.data.listeResponsables
          })
          .catch(erreur => alert(erreur))
      },
      onSauvegarder () {
        restApiService
          .put('/api/kiosques', this.kiosque)
          .then(() => {
            this.$dialog.notify.success(i18n.t('general.sauvegarde'))
          })
          .catch(erreur => alert(erreur))
        if (Object.keys(this.mediaImage).length) {
          restApiService
            .put(`/api/kiosques/${this.kiosque.id}/media`, this.mediaImage)
            .catch(erreur => alert(erreur))
        }
        if (Object.keys(this.mediaLogo).length) {
          restApiService
            .put(`/api/kiosques/${this.kiosque.id}/mediaLogo`, this.mediaLogo)
            .catch(erreur => alert(erreur))
        }
      },
      onEnleverResponsable (affId) {
        restApiService
          .delete(`/api/aff-resp-kiosques/${affId}`)
          .then(() => {
            this.chargerResponsables()
          })
          .catch(erreur => alert(erreur))
      },
      onResponsableIdentifié (e) {
        const info = {
          kiosque: {
            id: this.kiosque.id,
          },
          usager: {
            id: e.id,
          },
          creePar: 'Toucan Client',
        }
        restApiService
          .post('/api/aff-resp-kiosques', info)
          .then(() => {
            this.chargerResponsables()
          })
          .catch(erreur => alert(erreur))
      },
    },
  //
  }
</script>
