<template>
  <v-dialog
    v-model="dialogue"
    max-width="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        data-cy="btnSelectionUsager"
        v-on="on"
      >
        {{ libelle }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('general.trouver_un_usager') }}
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          ref="autocomplete"
          v-model="usagerIdentifié"
          :items="listeUsagersFiltré"
          item-disabled="disabled"
          :search-input.sync="recherche"
          :item-text="texte"
          item-value="id"
          :label="$t('general.usager')"
          cache-items
          data-cy="autoCSelectionUsager"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialogue = false"
        >
          {{ $t('general.annuler') }}
        </v-btn>
        <v-btn
          text
          :disabled="!usagerIdentifié"
          @click="onOk()"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {
    props: {
      libelle: {
        type: String,
        default: i18n.t('general.ajouter_un_usager'),
      },
      usagersInscrits: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      listeUsagers: [],
      usagerIdentifié: undefined,
      headers: [
        { text: i18n.t('general.nom'), value: 'nomComplet' },
      ],
      dialogue: undefined,
      recherche: null,
      listeUsagersFiltré: [],
    }),
    watch: {
      recherche: function (val) {
        this.majListe(val)
      },
      usagersInscrits: function (val) {
        this.filtrerLaListe(val)
      },
      listeUsagers: function (val) {
        this.filtrerLaListe(this.usagersInscrits)
      },
    },
    mounted () {
      this.majListe('')
    },
    methods: {
      filtrerLaListe (val) {
        this.listeUsagersFiltré = this.listeUsagers.map(u => ({
          ...u,
          disabled: val.filter(e => e === u.id).length > 0,
        }))
        if (this.$refs.autocomplete && this.$refs.autocomplete.cachedItems.length >= 1) {
          console.log('this.$refs.autocomplete.cachedItems', this.$refs.autocomplete.cachedItems)
          this.$refs.autocomplete.cachedItems = this.$refs.autocomplete.cachedItems.map(u => ({
            ...u,
            disabled: val.filter(e => e === u.id).length > 0,
          }))
        }
      },
      majListe (mots) {
        restApiService
          .get(`/api/usagers?mots=${mots}&size=200`)
          .then((result) => {
            this.listeUsagers = result.data
          })
      },
      // filter (item, queryText, itemText) {
      //   const str = (item.prenom + item.nom).toLowerCase()
      //   const searchText = queryText.toLowerCase()
      //   return str.indexOf(searchText) > -1
      // },
      texte (item) {
        return `${item.prenom} ${item.nom} (${item.courriel})`
      },
      onOk () {
        this.dialogue = false
        this.$emit('usager-selection', { id: this.usagerIdentifié })
      },
    },
  }
</script>
